<ejs-sidebar [animate]="true" [enableGestures]="false" [isOpen]="isOpen()" [type]="'Over'">
  <ul class="list-unstyled components">
    <li>
      @for (item of dmsNavigation(); track item.routerLink) {
        @if (item.visible) {
          <div>
            <a
              [routerLink]="item.childs ? null : item.routerLink"
              (click)="toggleExpand(item, $event)"
              [class.has-children]="item.childs"
              [routerLinkActive]="['active']"
              class="nav-item"
            >
              {{ item.text | translate }}
              @if (item.childs) {
                <span class="expand-icon">{{ item.isExpanded ? '−' : '+' }}</span>
              }
            </a>
            @if (item.childs && item.isExpanded) {
              <div class="child-items">
                @for (child of item.childs; track child.routerLink) {
                  @if (child.visible) {
                    <a
                      class="childLink"
                      [routerLink]="child.routerLink"
                      [routerLinkActive]="['active']"
                    >
                      {{ child.text | translate }}
                    </a>
                  }
                }
              </div>
            }
          </div>
        }
      }
    </li>

    @if (isAdmin()) {
      <br />
      <br />
      <br />
      <div class="categoryHeader nav-item">Administration</div>
      <li>
        @for (item of adminNavigation(); track item.routerLink) {
          @if (item.visible) {
            <div>
              <a
                class="nav-item"
                [routerLink]="item.childs ? null : item.routerLink"
                [routerLinkActive]="['active']"
                (click)="toggleExpand(item, $event)"
                [class.has-children]="item.childs"
              >
                {{ item.text | translate }}
                @if (item.childs) {
                  <span class="expand-icon">{{ item.isExpanded ? '−' : '+' }}</span>
                }
              </a>
              @if (item.childs && item.isExpanded) {
                <div class="child-items">
                  @for (child of item.childs; track child.routerLink) {
                    @if (child.visible) {
                      <a class="childLink" [routerLink]="child.routerLink">
                        {{ child.text | translate }}
                      </a>
                    }
                  }
                </div>
              }
            </div>
          }
        }
      </li>
    }
  </ul>
  <div class="navbar-bottom">
    <span class="nav-item info-item">{{ userStore.fullName() }}</span>
    <button (click)="openSettings()" class="nav-item">
      <span>{{ 'Menu.User.Settings' | translate }} </span> <i class="e-icons e-settings"></i>
    </button>
    <button (click)="logout()" class="nav-item">
      <span>{{ 'Menu.User.Logout' | translate }} </span> <i class="e-icons e-export"></i>
    </button>
    <p class="version-info">Dev 0.1.24</p>
  </div>
</ejs-sidebar>

@if (showAppSettings) {
  <hk-app-settings (close)="showAppSettings = false"></hk-app-settings>
}
