import { Routes } from '@angular/router';
import { AuthGuard } from './guards/authentication.guard';
import { AdminGuard } from './guards/admin.guard';

export const routes: Routes = [
  {
    path: 'login',
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage),
  },
  {
    path: 'archive',
    loadComponent: () => import('./pages/archive/archive.page').then(m => m.ArchivePage),
    canActivate: [AuthGuard],
  },
  {
    path: 'viewer/:id',
    loadComponent: () => import('./pages/viewer/viewer.page').then(m => m.ViewerPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadComponent: () => import('./pages/search/search.page').then(m => m.SearchPage),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'administration',
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: 'index/year',
        loadComponent: () =>
          import('./pages/admin/index/year/adminYear.page').then(m => m.AdminYearPage),
      },
      {
        path: 'index/doctype',
        loadComponent: () =>
          import('./pages/admin/index/doctype/adminDoctype.page').then(m => m.AdminDoctypePage),
      },
      {
        path: 'index/mask',
        loadComponent: () =>
          import('./pages/admin/index/mask/adminMask.page').then(m => m.AdminMaskPage),
      },
      {
        path: 'user',
        loadComponent: () => import('./pages/admin/user/adminUser.page').then(m => m.AdminUserPage),
      },
    ],
  },
];
