import { Injectable, signal, effect, Renderer2, RendererFactory2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

/**
 * @description Enum representing available themes
 */
export enum Theme {
  Light = 'light-theme',
  Dark = 'dark-theme',
  Dim = 'dim-theme',
  System = 'system'
}

/**
 * @description Service to manage application theming
 */
@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  private readonly THEME_KEY = 'app-theme';
  private mediaQuery: MediaQueryList;

  /**
   * @description Signal holding the current theme selection
   * @type {import('@angular/core').WritableSignal<Theme>}
   */
  themeSignal = signal<Theme>(this.getInitialTheme());

  /**
   * @description Initialize the ThemeService
   * @param {RendererFactory2} rendererFactory
   * @param {Document} document
   * @returns {void}
   */
  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    effect(() => {
      const theme = this.themeSignal();
      this.updateBodyClass(this.getEffectiveTheme(theme));
      localStorage.setItem(this.THEME_KEY, theme);
    });

    this.mediaQuery.addEventListener('change', () => {
      if (this.themeSignal() === Theme.System) {
        this.updateBodyClass(this.getSystemTheme());
      }
    });
  }

  /**
   * @description Get the initial theme from localStorage or default to System
   * @returns {Theme}
   */
  private getInitialTheme(): Theme {
    const savedTheme = localStorage.getItem(this.THEME_KEY) as Theme;
    return Object.values(Theme).includes(savedTheme) ? savedTheme : Theme.System;
  }

  /**
   * @description Set the current theme
   * @param {Theme} theme
   * @returns {void}
   */
  setTheme(theme: Theme): void {
    this.themeSignal.set(theme);
  }

  /**
   * @description Toggle between available themes in a predefined order
   * @returns {void}
   */
  toggleTheme(): void {
    const currentTheme = this.themeSignal();
    switch (currentTheme) {
      case Theme.Light:
        this.setTheme(Theme.Dark);
        break;
      case Theme.Dark:
        this.setTheme(Theme.Dim);
        break;
      case Theme.Dim:
        this.setTheme(Theme.System);
        break;
      case Theme.System:
      default:
        this.setTheme(Theme.Light);
        break;
    }
  }

  /**
   * @description Get the system's preferred color scheme
   * @returns {Theme.Light | Theme.Dark}
   */
  private getSystemTheme(): Theme.Light | Theme.Dark {
    return this.mediaQuery.matches ? Theme.Dark : Theme.Light;
  }

  /**
   * @description Get the effective theme based on current selection
   * @param {Theme} theme
   * @returns {Theme.Light | Theme.Dark | Theme.Dim}
   */
  private getEffectiveTheme(theme: Theme): Theme.Light | Theme.Dark | Theme.Dim {
    return theme === Theme.System ? this.getSystemTheme() : theme;
  }

  /**
   * @description Update the body class to reflect the current theme
   * @param {Theme.Light | Theme.Dark | Theme.Dim} themeName
   * @returns {void}
   */
  private updateBodyClass(themeName: Theme.Light | Theme.Dark | Theme.Dim): void {
    const body = this.document.body;
    const themeClasses = [Theme.Light, Theme.Dark, Theme.Dim];

    themeClasses.forEach(theme => {
      if (theme === themeName) {
        this.renderer.addClass(body, theme);
      } else {
        this.renderer.removeClass(body, theme);
      }
    });

    switch (themeName) {
      case Theme.Light:
        // Logic for Light Theme
        break;
      case Theme.Dark:
        // Logic for Dark Theme
        break;
      case Theme.Dim:
        // Logic for Dim Theme
        break;
    }
  }
}
