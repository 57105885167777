<hk-header (toggleSidebar)="toggleNavbar()" style="z-index: 1001"></hk-header>

<div class="main">
  <div
    (@navbarState.done)="animationDone($event)"
    [@navbarState]="navbarVisible ? 'open' : 'closed'"
    class="navbar-container"
  >
    @if (showNavbarContent && router.url !== '/welcome' && router.url !== '/') {
      <hk-navbar
        [isOpen]="showNavbar"
        style="z-index: 20; box-shadow: 2px 0 4px var(--border-color)"
      ></hk-navbar>
    }
  </div>
  <div class="router-outlet-container" style="z-index: 10">
    @defer {
      <router-outlet></router-outlet>
    }
  </div>
</div>

<div id="toast_container"></div>
