import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserStore } from '../store/user/user.store';
import { ToastService } from '../services/toast.service';

export const AdminGuard: CanActivateFn = () => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  const toast = inject(ToastService);

  if (userStore.isAdmin()) {
    return true;
  }

  toast.show(
    'Error',
    'Zugriff verweigert',
    'Sie haben keine Berechtigung die Administration zu starten',
  );
  void router.navigateByUrl('search');
  return false;
};
