import { Component, computed, inject, output } from '@angular/core';
import { MenuItemModel, MenuModule, ToolbarAllModule } from '@syncfusion/ej2-angular-navigations';
import { Theme, ThemeService } from '../../services/theme/theme.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/security/auth.service';
import { TranslationService } from '../../services/translation/translation.service';
import { UserStore } from '../../store/user/user.store';
import { AdminStore } from '../../store/app/administration.store';

@Component({
  selector: 'hk-header',
  standalone: true,
  imports: [MenuModule, ToolbarAllModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  toggleSidebar = output<void>();

  router = new Router();
  themeService = inject(ThemeService);
  authService = inject(AuthService);
  translationService = inject(TranslationService);
  userStore = inject(UserStore);
  adminStore = inject(AdminStore);

  isAdmin = this.userStore.isAdmin;
  adminActive = this.adminStore.adminActive;

  userMenuItems = computed(() => [
    {
      text: this.userStore.fullName() || this.translationService.translate('Menu.User.Title'),
      items: [
        {
          text: this.translationService.translate('Menu.User.Settings'),
          id: 'user_settings',
        },
        {
          text: this.translationService.translate('Menu.User.Logout'),
          id: 'user_logout',
        },
      ],
    },
  ]);

  themeMenuItems: MenuItemModel[] = [
    {
      text: this.translationService.translate('Menu.Theme.Title'),
      items: [
        { text: this.translationService.translate('Menu.Theme.Light'), id: Theme.Light },
        { text: this.translationService.translate('Menu.Theme.Dark'), id: Theme.Dark },
        { text: this.translationService.translate('Menu.Theme.Dim'), id: Theme.Dim },
        { text: this.translationService.translate('Menu.Theme.System'), id: Theme.System },
      ],
    },
  ];

  toggleNavbar() {
    this.toggleSidebar.emit();
  }

  onThemeSelect(args: { item: MenuItemModel }) {
    if (args.item.id) {
      this.themeService.setTheme(args.item.id as Theme);
    }
  }

  onUserSettingSelect(args: { item: MenuItemModel }) {
    if (args.item.id) {
      switch (args.item.id) {
        case 'user_logout': {
          void this.authService.logout();
          break;
        }
      }
    }
  }

  activateAdministration() {
    this.adminStore.activateAdministration();
    void this.router.navigateByUrl('administration/user');
  }

  deactivateAdministration() {
    this.adminStore.deactivateAdministration();
    void this.router.navigateByUrl('search');
  }
}
