import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { NavBarComponent } from './layout/navbar/navbar.component';

import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import { registerLocaleData } from '@angular/common';

import localeDe from '@angular/common/locales/de';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import { SpinnerComponent } from './layout/spinner/spinner.component';
import { TranslationService } from './services/translation/translation.service';

@Component({
  selector: 'hk-root',
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, NavBarComponent, SpinnerComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'herkules';
  showNavbar = true;
  router = new Router();
  translationService = inject(TranslationService);

  constructor() {
    // Deutsche Lokalisierung für Angular registrieren
    registerLocaleData(localeDe);

    // CLDR-Daten für Syncfusion laden
    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

    // Kultur auf Deutsch setzen
    setCulture('de');

    // Deutsche Übersetzungen für Syncfusion-Komponenten
    L10n.load({
      de: {
        grid: {
          EmptyRecord: 'Keine Aufzeichnungen zu zeigen',
          GroupDropArea: 'Ziehen Sie einen Spaltenkopf hierher, um die Spalte zu gruppieren',
          UnGroup: 'Klicken Sie hier, um die Gruppierung aufzuheben',
          Item: 'Eintrag',
          Items: 'Einträge',
        },
        pager: {
          currentPageInfo: '{0} von {1} Seiten',
          totalItemsInfo: '({0} Einträge)',
          firstPageTooltip: 'Zur ersten Seite',
          lastPageTooltip: 'Zur letzten Seite',
          nextPageTooltip: 'Zur nächsten Seite',
          previousPageTooltip: 'Zurück zur letzten Seite',
          nextPagerTooltip: 'Zum nächsten Pager',
          previousPagerTooltip: 'Zum vorherigen Pager',
        },
        datepicker: {
          today: 'Heute',
          placeholder: 'Wählen Sie ein Datum',
        },
      },
    });
  }

  ngOnInit() {
    this.translationService
      .loadTranslations('de')
      .then(() => {
        console.log('Translation loaded');
      })
      .catch((e: Error) => {
        console.error(e);
      });
  }

  toggleNavbar() {
    this.showNavbar = !this.showNavbar;
  }
}
