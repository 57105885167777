import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/security/auth.service';
import { ToastService } from '../services/toast.service';

type RequestBody = Record<string, unknown>;

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  private toastService = inject(ToastService);

  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<RequestBody>,
    next: HttpHandler,
  ): Observable<HttpEvent<RequestBody | HttpResponse<RequestBody>>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle unauthorized errors
          void this.handleUnauthorizedError();
        }
        return throwError(() => new Error(this.formatErrorMessage(error)));
      }),
    );
  }

  private async handleUnauthorizedError(): Promise<void> {
    try {
      this.toastService.show('Error', 'Ungültige Sitzung', 'Abmeldung erfolgt');
      await this.authService.logout();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  }

  private formatErrorMessage(error: HttpErrorResponse): string {
    return error.error?.message || error.message || 'An unknown error occurred';
  }
}
