<hk-header (toggleSidebar)="toggleNavbar()" style="z-index: 1001"></hk-header>

<div class="main">
  @if (
    showNavbarContent &&
    navbarVisible &&
    router.url !== '/welcome' &&
    router.url !== '/' &&
    router.url !== '/init'
  ) {
    <div
      (@navbarState.done)="animationDone($event)"
      [@navbarState]="navbarVisible ? 'open' : 'closed'"
      class="navbar-container"
    >
      @if (
        showNavbar &&
        !router.url.includes('/welcome') &&
        !router.url.includes('/init') &&
        router.url !== '/'
      ) {
        <hk-navbar
          [isOpen]="showNavbar"
          (navigationTriggered)="device.isMobile() ? toggleNavbar() : null"
          style="z-index: 20; box-shadow: 2px 0 4px var(--border-color)"
        ></hk-navbar>
      }
    </div>
  }

  <div class="router-outlet-container" style="z-index: 10">
    @defer {
      <router-outlet></router-outlet>
    }
  </div>
</div>

<div id="toast_container"></div>

@if (masterDataStore.isError()) {
  <hk-confirm-dialog
    [text]="'MasterData.Warning.LoadingMessage' | translate"
    (result)="masterDataErrorHandling($event)"
    [submitOnly]="true"
    [title]="'MasterData.Warning.LoadingTitle'"
  ></hk-confirm-dialog>
}
