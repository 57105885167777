import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserStore } from '../store/user/user.store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  userStore = inject(UserStore);

  constructor(
    protected override router: Router,
    keycloakAngular: KeycloakService,
  ) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(): Promise<boolean> {
    if (!this.authenticated) {
      await this.keycloakAngular.login({
        redirectUri: window.location.origin + '/login',
      });
      return false;
    }
    const user = await this.keycloakAngular.loadUserProfile();
    this.userStore.setUser(user);
    return true;
  }
}
