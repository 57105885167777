import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import {
  KeycloakAngularModule,
  KeycloakBearerInterceptor,
  KeycloakService,
} from 'keycloak-angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { loadCldr } from '@syncfusion/ej2-base';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import { environment } from '../environments/environment';
import { TranslationService } from './services/translation/translation.service';
import { UserStore } from './store/user/user.store';

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://keycloak.herkules.starke-dms.cloud',
        realm: 'herkulesdev',
        clientId: environment.clientId,
      },
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/public/assets/silent-check-sso.html',
        redirectUri: window.location.origin + '/welcome',
      },
      loadUserProfileAtStartUp: true,
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets', '/public/assets'],
    });
}

// Factory-Funktion für User-Profile-Initialisierung
function initializeUserProfile(keycloak: KeycloakService, userStore: UserStore) {
  return async () => {
    try {
      const profile = await keycloak.loadUserProfile();
      //@ts-expect-error don't know why, but setUser is not recognized by TS as prop of userStore, even though its visible in the type definition. Everything is working fine.
      userStore.setUser(profile);

      keycloak.getKeycloakInstance().onAuthSuccess = () => {
        //@ts-expect-error don't know why, but setUser is not recognized by TS as prop of userStore, even though its visible in the type definition. Everything is working fine.
        void keycloak.loadUserProfile().then(profile => userStore.setUser(profile));
      };

      keycloak.getKeycloakInstance().onTokenExpired = () => {
        //@ts-expect-error don't know why, but setUser is not recognized by TS as prop of userStore, even though its visible in the type definition. Everything is working fine.
        void keycloak.loadUserProfile().then(profile => userStore.setUser(profile));
      };
    } catch (error) {
      console.error('Failed to initialize user profile:', error);
    }
  };
}

function initializeTranslation(translationService: TranslationService) {
  return () => translationService.loadTranslations('de');
}

// CLDR-Daten laden
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(HttpClientModule, KeycloakAngularModule),
    {
      provide: APP_INITIALIZER,
      useFactory: (
        keycloak: KeycloakService,
        userStore: UserStore,
        translationService: TranslationService,
      ) => {
        const keycloakInit = initializeKeycloak(keycloak);
        const userInit = initializeUserProfile(keycloak, userStore);
        const translationInit = initializeTranslation(translationService);

        return async () => {
          await keycloakInit();
          await userInit();
          await translationInit();
        };
      },
      deps: [KeycloakService, UserStore, TranslationService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
  ],
};
