import { inject, LOCALE_ID, provideAppInitializer } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { loadCldr } from '@syncfusion/ej2-base';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import { TranslationService } from './services/translation/translation.service';
import { UserStore } from './store/user/user.store';
import { AuthService } from './services/security/auth.service';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ThemeService } from './services/theme/theme.service';

// CLDR-Daten laden
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

export const appConfig = {
  providers: [
    // Basis-Provider
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(routes, withComponentInputBinding()),
    provideAppInitializer(
      async (authService = inject(AuthService), userStore = inject(UserStore)) => {
        void inject(TranslationService).loadTranslations(
          localStorage.getItem(TranslationService.STORAGE_VALUE) || 'de',
        );
        inject(ThemeService).setTheme();
        try {
          // Initialisiere Auth
          const isAuthenticated = await authService.initialize();

          if (isAuthenticated) {
            // Lade Benutzerprofil
            const profile = await authService.getUserProfile();
            userStore.setUser(profile);

            // Setup Token Refresh Handler
            authService.setupTokenRefresh(() => {
              void authService.getUserProfile().then(updatedProfile => {
                userStore.setUser(updatedProfile);
              });
            });
          }
        } catch (error) {
          console.error('App initialization failed:', error);
        }
      },
    ),

    // HTTP Interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
  ],
};
