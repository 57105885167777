import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { UserStore } from '../store/user/user.store';
import { MasterDataStore } from '../store/master-data/masterData.store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  userStore = inject(UserStore);
  masterDataStore = inject(MasterDataStore);

  constructor(
    protected override router: Router,
    keycloakAngular: KeycloakService,
  ) {
    super(router, keycloakAngular);
  }

  async isAccessAllowed(): Promise<boolean> {
    if (!this.authenticated) {
      await this.keycloakAngular.login({ redirectUri: `` });
      return false;
    }
    this.masterDataStore.loadMasterData();
    const user = await this.keycloakAngular.loadUserProfile();
    this.userStore.setUser(user);
    const roles = this.keycloakAngular.getUserRoles();
    if (roles.includes('orga_admin')) {
      this.userStore.setAdmin(true);
    }

    return true;
  }
}
