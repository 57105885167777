import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { NavBarComponent } from './layout/navbar/navbar.component';

import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import { TranslationService } from './services/translation/translation.service';

import {
  animate,
  AnimationEvent,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { GlobalizationService } from './services/translation/globalization.service';
import { ConfirmDialogComponent } from './layout/dialog/confirm-dialog.component';
import { TranslatePipe } from './pipes/translation/translation.pipe';
import { MasterDataStore } from './store/master-data/masterData.store';
import { AuthService } from './services/security/auth.service';
import { DeviceService } from './services/device/device.service';

type SupportedLocaleKeys = 'en' | 'en-US' | 'de' | 'de-CH';

@Component({
  selector: 'hk-root',
  imports: [RouterOutlet, HeaderComponent, NavBarComponent, ConfirmDialogComponent, TranslatePipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    trigger('navbarState', [
      state(
        'closed',
        style({
          width: '0px',
        }),
      ),
      state(
        'open',
        style({
          width: '200px',
        }),
      ),
      transition('closed => open', [
        group([
          animate(
            '300ms ease-out',
            style({
              width: '200px',
            }),
          ),
        ]),
      ]),
      transition('open => closed', [
        group([
          animate(
            '300ms ease-in',
            style({
              width: '0px',
            }),
          ),
        ]),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'herkules';
  router = new Router();
  translationService = inject(TranslationService);
  globalizationService = inject(GlobalizationService);
  device = inject(DeviceService);
  showNavbar = this.device.isDesktop() || this.device.isTablet();
  navbarVisible = this.device.isDesktop() || this.device.isTablet();
  showNavbarContent = this.device.isDesktop() || this.device.isTablet();
  masterDataStore = inject(MasterDataStore);
  aouthService = inject(AuthService);

  constructor() {
    // CLDR-Daten für Syncfusion laden
    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

    // set Culture to stored value
    setCulture(localStorage.getItem(TranslationService.STORAGE_VALUE) || 'de');

    //TODO: Refactor into own file for clean up or simplify with translation key -> no locale set necessary

    // Deutsche Übersetzungen für Syncfusion-Komponenten
    L10n.load({
      de: {
        grid: {
          EmptyRecord: 'Keine Aufzeichnungen zu zeigen',
          GroupDropArea: 'Ziehen Sie einen Spaltenkopf hierher, um die Spalte zu gruppieren',
          UnGroup: 'Klicken Sie hier, um die Gruppierung aufzuheben',
          Item: 'Eintrag',
          Items: 'Einträge',
          Add: 'Hinzufügen',
          Edit: 'Bearbeiten',
          Delete: 'Löschen',
          Update: 'Aktualisieren',
          Cancel: 'Abbrechen',
          Search: 'Suchen',

          // Paging
          FirstPage: 'Erste Seite',
          LastPage: 'Letzte Seite',
          PreviousPage: 'Vorherige Seite',
          NextPage: 'Nächste Seite',

          // Filter
          FilterTrue: 'Wahr',
          FilterFalse: 'Falsch',
        },
        pager: {
          currentPageInfo: '{0} von {1} Seiten',
          totalItemsInfo: '({0} Einträge)',
          firstPageTooltip: 'Zur ersten Seite',
          lastPageTooltip: 'Zur letzten Seite',
          nextPageTooltip: 'Zur nächsten Seite',
          previousPageTooltip: 'Zurück zur letzten Seite',
          nextPagerTooltip: 'Zum nächsten Pager',
          previousPagerTooltip: 'Zum vorherigen Pager',
        },
        datepicker: {
          today: 'Heute',
          placeholder: 'Wählen Sie ein Datum',
        },
      },
      en: {
        grid: {
          EmptyRecord: 'No Records Available',
          GroupDropArea: 'Drag to Gourp columns',
          UnGroup: 'Click here to dismiss grouping',
          Item: 'Entry',
          Items: 'Entries',
          Add: 'Add',
          Edit: 'Edit',
          Delete: 'Delete',
          Update: 'Update',
          Cancel: 'Cancel',
          Search: 'Search',

          // Paging
          FirstPage: 'First page',
          LastPage: 'Last page',
          PreviousPage: 'Previous page',
          NextPage: 'Next page',

          // Filter
          FilterTrue: 'True',
          FilterFalse: 'False',
        },
        pager: {
          currentPageInfo: '{0} of {1} pages',
          totalItemsInfo: '({0} entries)',
          firstPageTooltip: 'go to first page',
          lastPageTooltip: 'go to last page',
          nextPageTooltip: 'go to next page',
          previousPageTooltip: 'back to prev page',
          nextPagerTooltip: 'next pager',
          previousPagerTooltip: 'prev pager',
        },
        datepicker: {
          today: 'Today',
          placeholder: 'Select a date',
        },
      },
    });
  }

  ngOnInit() {
    const storedLanguage = localStorage.getItem(TranslationService.STORAGE_VALUE);
    const storedLocale = localStorage.getItem(GlobalizationService.STORAGE_VALUE);
    this.translationService
      .loadTranslations(storedLanguage || 'de')
      .then(() => {
        console.debug('Translation loaded');
      })
      .catch((e: Error) => {
        console.error(e);
      });

    void this.globalizationService.setCurrentLocale((storedLocale || 'de') as SupportedLocaleKeys);
  }

  /**
   * toggle navbar
   */
  toggleNavbar() {
    this.showNavbar = !this.showNavbar;
    if (this.showNavbar) {
      this.showNavbarContent = true;
      this.navbarVisible = true;
    } else {
      this.navbarVisible = false;
    }
  }

  /**
   * handle animation state
   * @param event
   */
  animationDone(event: AnimationEvent) {
    if (event.toState === 'closed') {
      this.showNavbarContent = false;
    }
  }

  masterDataErrorHandling($event: boolean) {
    if ($event) {
      void this.aouthService.logout();
    }
  }
}
