import { Routes } from '@angular/router';
import { AuthGuard } from './guards/authentication.guard';
import { AdminGuard } from './guards/admin.guard';

export const routes: Routes = [
  {
    path: 'welcome',
    loadComponent: () => import('./pages/welcome/welcome.page').then(m => m.WelcomePage),
  },
  {
    path: 'archive',
    loadComponent: () => import('./pages/archive/archive.page').then(m => m.ArchivePage),
    canActivate: [AuthGuard],
  },
  {
    path: 'viewer/:id',
    loadComponent: () => import('./pages/viewer/viewer.page').then(m => m.ViewerPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'search',
    loadComponent: () => import('./pages/search/search.page').then(m => m.SearchPage),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: 'administration',
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: 'config/year',
        loadComponent: () =>
          import('./pages/admin/index/year/adminYear.page').then(m => m.AdminYearPage),
      },
      {
        path: 'mailagent',
        loadComponent: () =>
          import('./pages/admin/mail-agent/mail-agent.page').then(m => m.MailAgentPage),
      },
    ],
  },
];
