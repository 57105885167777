<ejs-toolbar>
  <e-items>
    @if (router.url !== '/login') {
      <e-item prefixIcon="e-icons e-menu" (click)="toggleNavbar()"></e-item>
    }
    <e-item>
      <ng-template #template
        ><h1 class="appTitle">Herkules {{ adminActive() ? 'Administration' : '' }}</h1>
      </ng-template>
    </e-item>

    @if (isAdmin()) {
      <e-item
        prefixIcon="e-icons e-export"
        align="Right"
        [text]="adminActive() ? 'DMS' : 'Administration'"
        (click)="adminActive() ? deactivateAdministration() : activateAdministration()"
      >
      </e-item>
    }
    <e-item align="Right">
      <ng-template #template>
        <ejs-menu (select)="onThemeSelect($event)" [items]="themeMenuItems"></ejs-menu>
      </ng-template>
    </e-item>

    @if (router.url !== '/login') {
      <e-item align="Right">
        <ng-template #template>
          <ejs-menu [items]="userMenuItems()" (select)="onUserSettingSelect($event)"></ejs-menu>
        </ng-template>
      </e-item>
    }
  </e-items>
</ejs-toolbar>
