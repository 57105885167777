import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { NavBarComponent } from './layout/navbar/navbar.component';

import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import { registerLocaleData } from '@angular/common';

import localeDe from '@angular/common/locales/de';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import { TranslationService } from './services/translation/translation.service';

import {
  animate,
  AnimationEvent,
  group,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  standalone: true,
  selector: 'hk-root',
  imports: [RouterOutlet, HeaderComponent, NavBarComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  animations: [
    trigger('navbarState', [
      state(
        'closed',
        style({
          width: '0px',
        }),
      ),
      state(
        'open',
        style({
          width: '200px',
        }),
      ),
      transition('closed => open', [
        group([
          animate(
            '300ms ease-out',
            style({
              width: '200px',
            }),
          ),
        ]),
      ]),
      transition('open => closed', [
        group([
          animate(
            '300ms ease-in',
            style({
              width: '0px',
            }),
          ),
        ]),
      ]),
    ]),
  ],
})
export class AppComponent implements OnInit {
  title = 'herkules';
  showNavbar = true;
  router = new Router();
  translationService = inject(TranslationService);
  navbarVisible = true;
  showNavbarContent = true;

  constructor() {
    // Deutsche Lokalisierung für Angular registrieren
    registerLocaleData(localeDe);

    // CLDR-Daten für Syncfusion laden
    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

    // Kultur auf Deutsch setzen
    setCulture('de');

    // Deutsche Übersetzungen für Syncfusion-Komponenten
    L10n.load({
      de: {
        grid: {
          EmptyRecord: 'Keine Aufzeichnungen zu zeigen',
          GroupDropArea: 'Ziehen Sie einen Spaltenkopf hierher, um die Spalte zu gruppieren',
          UnGroup: 'Klicken Sie hier, um die Gruppierung aufzuheben',
          Item: 'Eintrag',
          Items: 'Einträge',
          Add: 'Hinzufügen',
          Edit: 'Bearbeiten',
          Delete: 'Löschen',
          Update: 'Aktualisieren',
          Cancel: 'Abbrechen',
          Search: 'Suchen',

          // Paging
          FirstPage: 'Erste Seite',
          LastPage: 'Letzte Seite',
          PreviousPage: 'Vorherige Seite',
          NextPage: 'Nächste Seite',

          // Filter
          FilterTrue: 'Wahr',
          FilterFalse: 'Falsch',
        },
        pager: {
          currentPageInfo: '{0} von {1} Seiten',
          totalItemsInfo: '({0} Einträge)',
          firstPageTooltip: 'Zur ersten Seite',
          lastPageTooltip: 'Zur letzten Seite',
          nextPageTooltip: 'Zur nächsten Seite',
          previousPageTooltip: 'Zurück zur letzten Seite',
          nextPagerTooltip: 'Zum nächsten Pager',
          previousPagerTooltip: 'Zum vorherigen Pager',
        },
        datepicker: {
          today: 'Heute',
          placeholder: 'Wählen Sie ein Datum',
        },
      },
    });
  }

  ngOnInit() {
    this.translationService
      .loadTranslations('de')
      .then(() => {
        console.log('Translation loaded');
      })
      .catch((e: Error) => {
        console.error(e);
      });
  }

  /**
   * toggle navbar
   */
  toggleNavbar() {
    this.showNavbar = !this.showNavbar;
    if (this.showNavbar) {
      this.showNavbarContent = true;
      this.navbarVisible = true;
    } else {
      this.navbarVisible = false;
    }
  }

  /**
   * handle animation state
   * @param event
   */
  animationDone(event: AnimationEvent) {
    if (event.toState === 'closed') {
      this.showNavbarContent = false;
    }
  }
}
