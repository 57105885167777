import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import {
  KeycloakAngularModule,
  KeycloakBearerInterceptor,
  KeycloakService,
} from 'keycloak-angular';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';
import { loadCldr } from '@syncfusion/ej2-base';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as gregorian from 'cldr-data/main/de/ca-gregorian.json';
import * as numbers from 'cldr-data/main/de/numbers.json';
import * as timeZoneNames from 'cldr-data/main/de/timeZoneNames.json';
import { environment } from '../environments/environment';
import { TranslationService } from './services/translation/translation.service';
import { UserStore } from './store/user/user.store';

// Neue Initialisierungsfunktion für UserStore
function initializeUserProfile() {
  const userStore = inject(UserStore);
  const keycloak = inject(KeycloakService);
  return async () => {
    try {
      const profile = await keycloak.loadUserProfile();
      userStore.setUser(profile);

      keycloak.getKeycloakInstance().onAuthSuccess = () => {
        void keycloak.loadUserProfile().then(profile => userStore.setUser(profile));
      };

      keycloak.getKeycloakInstance().onTokenExpired = () => {
        void keycloak.loadUserProfile().then(profile => userStore.setUser(profile));
      };
    } catch (error) {
      console.error('Failed to initialize user profile:', error);
    }
  };
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://keycloak.herkules.starke-dms.cloud',
        realm: 'herkulesdev',
        clientId: environment.clientId,
      },
      initOptions: {
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/public/assets/silent-check-sso.html',
        redirectUri: window.location.origin + '/login',
      },
      loadUserProfileAtStartUp: true,
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets', '/public/assets'],
    });
}

function initializeTranslation(translationService: TranslationService) {
  return () => translationService.loadTranslations('de');
}

// CLDR-Daten laden
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideHttpClient(),
    provideRouter(routes, withComponentInputBinding()),
    importProvidersFrom(HttpClientModule, KeycloakAngularModule),
    // Keycloak Initialization
    {
      provide: APP_INITIALIZER,
      useFactory: () => {
        const keycloak = inject(KeycloakService);
        const keycloakInit = initializeKeycloak(keycloak);
        const userInit = initializeUserProfile();

        // Verketten der Initialisierungen
        return async () => {
          await keycloakInit();
          await userInit();
        };
      },
      multi: true,
      deps: [KeycloakService],
    },
    // Translation Initialization
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTranslation,
      multi: true,
      deps: [TranslationService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
  ],
};
