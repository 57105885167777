import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export interface AdminState {
  adminActive: boolean;
}

const initialState: AdminState = {
  adminActive: false,
};

export const AdminStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(store => ({
    activateAdministration() {
      patchState(store, { adminActive: true });
    },
    deactivateAdministration() {
      patchState(store, { adminActive: false });
    },
  })),
);
