// navbar.component.ts
import { Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarAllModule } from '@syncfusion/ej2-angular-navigations';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '../../pipes/translation/translation.pipe';
import { AdminStore } from '../../store/app/administration.store';
import { TranslationService } from '../../services/translation/translation.service';

interface navItem {
  routerLink: string;
  text: string;
  childs?: navItem[];
  isExpanded?: boolean;
  visible: boolean;
}

@Component({
  standalone: true,
  selector: 'hk-navbar',
  imports: [CommonModule, SidebarAllModule, RouterLink, TranslatePipe],
  providers: [TranslatePipe],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavBarComponent {
  isOpen = input<boolean>(true);

  private translationService = inject(TranslationService);
  adminNavigation: navItem[] = [
    {
      routerLink: '/administration/config/year',
      text: this.translationService.translate('Navigation.AdminYear'),
      visible: true,
    },

    {
      routerLink: '/administration/mailagent',
      text: this.translationService.translate('Navigation.AdminMailAgent'),
      visible: true,
    },
  ];

  dmsNavigation: navItem[] = [
    {
      routerLink: '/archive',
      text: this.translationService.translate('Navigation.Archive'),
      isExpanded: false,
      visible: true,
    },
    {
      routerLink: '/search',
      text: this.translationService.translate('Navigation.Search'),
      isExpanded: false,
      visible: true,
    },
  ];

  private adminStore = inject(AdminStore);
  listItems = computed(() =>
    this.adminStore.adminActive() ? this.adminNavigation : this.dmsNavigation,
  );

  toggleExpand(item: navItem, event: Event) {
    if (item.childs) {
      event.preventDefault();
      item.isExpanded = !item.isExpanded;
    }
  }
}
