import { Injectable, signal, computed } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdminStore {
  // Private Signal für den State
  private adminActiveSignal = signal<boolean>(false);

  // Public Computed für den Zugriff von außen
  public adminActive = computed(() => this.adminActiveSignal());
}
