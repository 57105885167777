// navbar.component.ts
import { Component, computed, inject, input, output, Signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarAllModule } from '@syncfusion/ej2-angular-navigations';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslatePipe } from '../../pipes/translation/translation.pipe';
import { TranslationService } from '../../services/translation/translation.service';
import { UserStore } from '../../store/user/user.store';
import { AppSettingsComponent } from '../../components/app-settings/app-settings.component';
import { AuthService } from '../../services/security/auth.service';
import { DeviceService } from '../../services/device/device.service';

interface navItem {
  routerLink: string;
  text: string;
  childs?: navItem[];
  isExpanded?: boolean;
  visible: boolean;
}

@Component({
  selector: 'hk-navbar',
  imports: [
    CommonModule,
    SidebarAllModule,
    RouterLink,
    TranslatePipe,
    AppSettingsComponent,
    RouterLinkActive,
  ],
  providers: [TranslatePipe],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavBarComponent {
  device = inject(DeviceService);
  isOpen = input<boolean>(this.device.isDesktop());
  navigationTriggered = output<void>();
  protected showAppSettings = false;
  protected userStore = inject(UserStore);
  protected isAdmin: Signal<boolean> = this.userStore.isAdmin;
  private translationService = inject(TranslationService);
  adminNavigation: Signal<navItem[]> = computed(() => [
    {
      routerLink: '/administration/config/year',
      text: this.translationService.translate('Navigation.AdminYear'),
      visible: true,
    },

    {
      routerLink: '/administration/mailagent',
      text: this.translationService.translate('Navigation.AdminMailAgent'),
      visible: true,
    },
  ]);
  dmsNavigation: Signal<navItem[]> = computed(() => [
    {
      routerLink: '/archive',
      text: this.translationService.translate('Navigation.Archive'),
      isExpanded: false,
      visible: true,
    },
    {
      routerLink: '/search',
      text: this.translationService.translate('Navigation.Search'),
      isExpanded: false,
      visible: true,
    },
  ]);
  private authService = inject(AuthService);

  openSettings() {
    this.showAppSettings = true;
  }

  toggleExpand(item: navItem, event: Event) {
    if (item.childs) {
      event.preventDefault();
      item.isExpanded = !item.isExpanded;
    } else {
      this.navigationTriggered.emit();
    }
  }

  logout() {
    void this.authService.logout();
  }
}
