<ejs-sidebar [animate]="true" [enableGestures]="false" [isOpen]="isOpen()" [type]="'Over'">
  <ul class="list-unstyled components">
    <li>
      @for (item of listItems(); track item.routerLink) {
        @if (item.visible) {
          <div class="nav-item">
            <a
              [routerLink]="item.childs ? null : item.routerLink"
              (click)="toggleExpand(item, $event)"
              [class.has-children]="item.childs"
            >
              {{ item.text | translate }}
              @if (item.childs) {
                <span class="expand-icon">{{ item.isExpanded ? '−' : '+' }}</span>
              }
            </a>
            @if (item.childs && item.isExpanded) {
              <div class="child-items">
                @for (child of item.childs; track child.routerLink) {
                  @if (child.visible) {
                    <a class="childLink" [routerLink]="child.routerLink">
                      {{ child.text | translate }}
                    </a>
                  }
                }
              </div>
            }
          </div>
        }
      }
    </li>
  </ul>
</ejs-sidebar>
