<hk-header (toggleSidebar)="toggleNavbar()" style="z-index: 30"></hk-header>

<div class="main">
  @if (showNavbar && router.url !== '/login') {
    @defer {
      <hk-navbar
        [isOpen]="showNavbar"
        style="z-index: 20; box-shadow: 2px 0 4px var(--border-color)"
      ></hk-navbar>
    }
  }
  <div class="router-outlet-container" style="z-index: 10">
    @defer {
      <router-outlet></router-outlet>
    }
  </div>
</div>
